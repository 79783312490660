<template>
    <div>
        <h3>Cartridges</h3>
        <hr>
        <b-container>
            <b-row>
                <b-col>
                    <b-button @click="addCartridge" variant="outline-success">
                        <b-icon icon="plus-circle"></b-icon> Add
                    </b-button>
                </b-col>
                <b-col>
                    <b-form-select
                        v-model="compSelect"
                        :options="compOptions"
                        :disabled="compSelDisable"
                        @change="compSelChange"
                    >
                    </b-form-select>
                </b-col>
            </b-row>
        </b-container>
        <hr>
        <b-table class="text-center"
                 striped
                 bordered
                 small
                 hover
                 selectable
                 ref="selectableTable"
                 select-mode="single"
                 sticky-header="true"
                 head-variant="light"
                 responsive="sm"
                 :items="items"
                 :fields="fields"
                 @row-selected="onRowSelected"
        >
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                </template>
            </template>
            <template #cell(edit)="row">
                <b-icon-pencil-fill @click="editItem(row.index)"></b-icon-pencil-fill>
            </template>
            <template #cell(delete)="row">
                <b-icon-trash-fill @click="delItem(row.index)"></b-icon-trash-fill>
            </template>
        </b-table>
        <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Delete Cartridge: {{selTempName}} ?</h5>
            </div>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="deleteYes">Yes (No Undo)</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="deleteNo">No</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <b-modal
            ref="add-temp-modal"
            hide-footer
            centered
            title="Add Cartridge"
        >
            <div>
                <b-container >
                    <b-form @submit="tempOK">
                        <b-form-group>
                            <label class="m-25" for="sys-addtemp">Name:</label>
                            <b-form-input
                                class="m-75"
                                id="sys-addtemp"
                                type="text"
                                v-model="form.name"
                                required
                            ></b-form-input>
                            <label class="m-25" for="sys-adddesc">Description:</label>
                            <b-form-input
                                class="m-75"
                                id="sys-adddesc"
                                type="text"
                                v-model="form.desc"
                                required
                            ></b-form-input>
                            <label class="m-25" for="sys-addtype">Type:</label>
                            <b-form-select
                                class="m-75"
                                id="sys-addtype"
                                v-model="form.cType"
                                :options="carts.cartTypes">
                                Type
                            </b-form-select>
                            <label class="m-25" for="sys-addsize">Size (Oz.):</label>
                            <b-form-input
                                class="m-75"
                                id="sys-addsize"
                                type="text"
                                v-model="form.cSize"
                                required
                            ></b-form-input>
                            <label class="m-25" for="sys-adddose">Dose/Rate:</label>
                            <b-form-input
                                class="m-75"
                                id="sys-adddose"
                                type="text"
                                v-model="form.cDose"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <hr>
                        <b-container class="text-center">
                            <b-row cols="2">
                                <b-col>
                                    <b-button variant="success" type="submit" block >OK</b-button>
                                </b-col>
                                <b-col>
                                    <b-button variant="warning" block @click="tempCancel">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-form>
                </b-container>
            </div>
        </b-modal>
        <b-modal
            ref="edit-temp-modal"
            hide-footer
            centered
            title="Edit Name"
        >
            <div>
                <b-container >
                    <b-form @submit="tempEditOK">
                        <b-form-group>
                            <label class="m-25" for="sys-edit-temp-old">Old Name:</label>
                            <b-form-input
                                readonly
                                class="m-75"
                                id="sys-edit-temp-old"
                                type="text"
                                :placeholder="selTempName"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <label class="m-25" for="sys-edit-temp">Name:</label>
                            <b-form-input
                                class="m-75"
                                id="sys-edit-temp"
                                type="text"
                                v-model="form.name"
                                required
                            ></b-form-input>
                            <label class="m-25" for="sys-adddesc">Description:</label>
                            <b-form-input
                                class="m-75"
                                id="sys-adddesc"
                                type="text"
                                v-model="form.desc"
                                required
                            ></b-form-input>
                            <label class="m-25" for="sys-addtype">Type:</label>
                            <b-form-select
                                class="m-75"
                                id="sys-addtype"
                                v-model="form.cType"
                                :options="carts.cartTypes">
                                Type
                            </b-form-select>
                            <label class="m-25" for="sys-addsize">Size (Oz.):</label>
                            <b-form-input
                                class="m-75"
                                id="sys-addsize"
                                type="text"
                                v-model="form.cSize"
                                required
                            ></b-form-input>
                            <label class="m-25" for="sys-adddose">Dose/Rate:</label>
                            <b-form-input
                                class="m-75"
                                id="sys-adddose"
                                type="text"
                                v-model="form.cDose"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <hr>
                        <b-container class="text-center">
                            <b-row cols="2">
                                <b-col>
                                    <b-button variant="success" type="submit" block >OK</b-button>
                                </b-col>
                                <b-col>
                                    <b-button variant="warning" block @click="tempEditCancel">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-form>
                </b-container>
            </div>
        </b-modal>
    </div>
</template>

<script>
import roles from '@/roles';
import carts from '@/cart-types';

export default {
    name: "CartridgeList",
    data() {
        return {
            fields: [
                {key: 'selected', label: 'Selected', thStyle: { width: '5rem'}},
                {key: 'name', label: 'Name'},
                {key: 'cDesc', label: 'Description'},
                {key: 'cType', label: 'Type', formatter: this.cTypeFormat},
                {key: 'cSize', label: 'Size (Oz.)'},
                {key: 'cDose', label: 'Dose/Rate'},
                {key: 'edit', label: 'Edit'},
                {key: 'delete', label: 'Delete'}
            ],
            selected: [],
            form: {
                name: '',
                desc: '',
                cType: 0,
                cSize: 64,
                cDose: 5
            },
            itemSelIdx: 0,
            compSelect: 0,
            compSelDisable: false
        }
    },
    created() {
        this.$store.dispatch('getCartridges');

    },
    mounted() {
        this.compSelect = this.$store.getters.company;
        if (this.$store.getters.role !== roles.roles.superUser)
            this.compSelDisable = true;
    },
    methods: {
        cTypeFormat(value) {
            for (let i = 0; i < carts.cartTypes.length; i++) {
                if (value === carts.cartTypes[i].value)
                    return carts.cartTypes[i].text;
            }
            return value;
        },
        compSelChange(value) {
            this.$store.commit('storeCartridgeCompSel', value);
            this.compSelect = value;
        },
        onRowSelected(items) {
            this.selected = items
            this.$store.commit('storeSelectedCartridge', this.selected[0]);
        },
        addCartridge() {
            //console.log("Add Template");
            this.form.name = '';
            this.form.desc = '';
            this.$refs['add-temp-modal'].show();
        },
        editItem(itemIndex) {
            this.itemSelIdx = itemIndex;
            this.form.name = this.$store.getters.adminCatridges[this.itemSelIdx].name;
            this.form.desc = this.$store.getters.adminCatridges[this.itemSelIdx].cDesc;
            this.form.cType = this.$store.getters.adminCatridges[this.itemSelIdx].cType;
            this.form.cSize = this.$store.getters.adminCatridges[this.itemSelIdx].cSize;
            this.form.cDose = this.$store.getters.adminCatridges[this.itemSelIdx].cDose;
            this.$refs['edit-temp-modal'].show();
        },
        delItem(itemIndex) {
            this.itemSelIdx = itemIndex;
            this.$refs['confirm-modal'].show();
        },
        tempOK(event) {
            event.preventDefault();
            let cID = this.form.name + "-" + this.compSelect.toString();
            let newCart = {
                name: this.form.name,
                company: this.compSelect,
                cID: cID,
                cType: this.form.cType,
                cSize: this.form.cSize,
                cDose: this.form.cDose,
                cDesc: this.form.desc
            };
            this.$store.dispatch('saveCartridge', newCart).then(() => {
                this.$bvToast.toast('Cartridge Created', {
                    title: 'Cartridge Create',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getCartridges');
            }).catch(() => {
                this.$bvToast.toast('Error Creating Cartridge', {
                    title: 'Cartridge Create',
                    variant: 'danger',
                    solid: true
                });
            });
            this.selected = newCart;
            this.$store.commit('storeSelectedCartridge', this.selected);
            this.$refs['add-temp-modal'].hide();
        },
        tempCancel() {
            this.$refs['add-temp-modal'].hide();
        },
        tempEditOK(event) {
            event.preventDefault();
            let cID = this.selTempName + "-" + this.compSelect.toString();
            this.$refs['edit-temp-modal'].hide();
            this.$store.dispatch('editCartridge', {
                name: this.form.name,
                company: this.compSelect,
                cID: cID,
                cType: this.form.cType,
                cSize: this.form.cSize,
                cDose: this.form.cDose,
                cDesc: this.form.desc
            }).then(() => {
                this.$store.dispatch('getCartridges').then(() => {
                    this.$bvToast.toast('Cartridge Edited', {
                        title: 'Cartridge Edit',
                        variant: 'success',
                        solid: true
                    });
                }).catch(() => {

                });
            }).catch(() => {
                this.$bvToast.toast('Error Editing Cartridge', {
                    title: 'Cartridge Edit',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        tempEditCancel() {
            this.$refs['edit-temp-modal'].hide();
        },
        deleteYes() {
            this.$refs['confirm-modal'].hide();
            const delName = this.selected[0].cID;
            this.$store.dispatch('deleteCartridge', delName).then(() => {
                this.$store.dispatch('getCartridges').then(() => {
                    this.$bvToast.toast('Cartridge Deleted', {
                        title: 'Cartridge Delete',
                        variant: 'success',
                        solid: true
                    });
                }).catch(() => {

                });
            }).catch(() => {
                this.$bvToast.toast('Error Deleting Cartridge', {
                    title: 'Cartridge Delete',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        deleteNo() {
            this.$refs['confirm-modal'].hide();
        }
    },
    computed: {
        carts() {
            return carts
        },
        items() {
            const tempCarts = this.$store.getters.adminCatridges;
            //console.log(tempCarts);
            let carts = [];
            if (tempCarts === null)
                return carts;
            for (let i = 0; i < tempCarts.length; i++) {
                if (tempCarts[i].company === this.compSelect)
                    carts.push(tempCarts[i]);
            }
            return carts;
        },
        selTempName() {
            if (this.selected === null || this.selected === undefined)
                return '';
            if (this.selected.length > 0) {
                return this.selected[0].name;
            }
            else
                return '';
        },
        compOptions() {
            const cList = this.$store.getters.compList;
            let cNameList = [];
            if (cList !== null) {
                for (let i = 0; i < cList.length; i++) {
                    let comp = {
                        text: cList[i].name,
                        value: i
                    };
                    cNameList.push(comp);
                }
            }
            return cNameList;
        }
    }
}
</script>

<style scoped>

</style>
