<template>
    <div>
        <h3>Cartridge Editor</h3>
        <hr>
        <cartridge-list></cartridge-list>
        <hr>
    </div>
</template>

<script>
import CartridgeList from "@/components/AdminModules/General/CartridgeList.vue";

export default {
    name: "CartridgeEditor",
    components: {
        CartridgeList
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
    computed: {

    }
}
</script>

<style scoped>

</style>
